import FeaturesBox from "./FeaturesBox";
import FeaturesListSection from "./FeaturesListSection";
import "./featuresSection.css";
function FeaturesSection() {
  return (
    <>
      <div className="featuresSectionHeading">FEATURES</div>
      <div className="feturesSectionContainer">
        <FeaturesBox
          heading={"24X7 Customer Support"}
          subText={"Always at your service via chat, email and phone "}
          iconName={"contactPic"}
        />
        <FeaturesBox
          heading={"Only Vetted Products"}
          subText={"10K + Guaranteed available Products & no dead leads "}
          iconName={"vettedPic"}
        />
        <FeaturesBox
          heading={"Fully Customizable"}
          subText={"Choose the options that work best for you"}
          iconName={"customizePic"}
        />
      </div>
      <FeaturesListSection />
    </>
  );
}
export default FeaturesSection;
