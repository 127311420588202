import { useLocation, useNavigate } from "react-router-dom";
import "./footer.css";
import { useAnlyParams } from "../../hooks/useAnlyParams";
function Footer() {
  const location = useLocation();
  const analyParamsCB = useAnlyParams();
  const navigate = useNavigate();

  const linkClickHandler = (linkName) => {
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: "footerLink_" + linkName,
      },
    });
    navigate("/" + linkName);
  };
  return (
    <div className="footerMainContainer">
      <div className="footerLinksContainer">
        <div className="footerLinksCol">
          <div
            className="footerLinksItem"
            onClick={() => linkClickHandler("#features")}
          >
            Features
          </div>
          <div
            className="footerLinksItem"
            onClick={() => {
              linkClickHandler("contact");
            }}
          >
            Contact Us
          </div>
          <div
            className="footerLinksItem"
            onClick={() => linkClickHandler("book")}
          >
            Book a Demo
          </div>
        </div>
        <div className="footerLinksCol">
          <div
            className="footerLinksItem"
            onClick={() => {
              linkClickHandler("privacy");
            }}
          >
            {" "}
            Privacy Policy
          </div>
          <div
            className="footerLinksItem"
            onClick={() => {
              linkClickHandler("terms");
            }}
          >
            Terms
          </div>
        </div>
        <div className="footerLinksCol">
          <div className="footerLinksItem">Email Us</div>
          <div className="footerLinksItem">web@scalerplus.com</div>
        </div>
      </div>

      <div className="footerCopyrightRow">
        2023 © copyright www.scalerplus.com <br />
        Regtd. London, United Kingdom
      </div>
    </div>
  );
}
export default Footer;
