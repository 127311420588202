import { useServerAPICalls } from "./useServerAPICalls";

export const useAnlyParams = () => {
  const serverAPICallback = useServerAPICalls();
  const analyParamsCallback = async (data) => {
    //console.log(data);
    data.pl = { ...data.pl, channel: "web" };
    // 2/2 Send the data
    serverAPICallback("post", "anlyparam", data);
  };
  return analyParamsCallback;
};
