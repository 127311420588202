import HorizontalLine from "../../UI/horizontalLine/HorizontalLine";
import "./easySellingSection.css";
import personPic from "../../../assets/personHappyNoBG.png";
import SimpleButton from "../../UI/buttons/simple/SimpleButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../../hooks/useAnlyParams";

function EasySellingSection() {
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  return (
    <>
      <HorizontalLine height={"5px"} color={"#D3D3D3"} margin={"1rem 0"} />
      <div className="easySellingContainer">
        <img
          src={personPic}
          className="easySellingPersonPic"
          atl="Smiling Person "
        />
        <div className="easySellingTextCotnainer">
          <div className="easySellingTextPrimaryLine">
            SELLING HAS NEVER BEEN EASIER, WITH JUST A FEW CLICKS:
          </div>
          <div className="easySellingTextSecondaryLine">
            Finding Profitable Products Sorted
          </div>
          <div className="easySellingTextSecondaryLine">
            Shipping and Stocking Sorted
          </div>
          <div className="easySellingTextSecondaryLine">
            Listing on Amz, eBay ,Etsy and other Platforms Sorted
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px 0 0 0",
            }}
          >
            <SimpleButton
              title={" Sign up "}
              color={"white"}
              backgroundColor={"#2FB672"}
              padding={".8rem"}
              fontSize={"1.2rem"}
              clickHandler={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "easySellingLink#register",
                  },
                });
                navigate("/#register");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default EasySellingSection;
