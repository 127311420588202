function CarouselItemsContainer({ children }) {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid grey",
        borderRadius: "30px",
        margin: "10px",
      }}
    >
      {children}
    </div>
  );
}
export default CarouselItemsContainer;
