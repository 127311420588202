import { useState } from "react";
import "./book.css";
import BookFormContents from "./BookFormContents";

function Book() {
  const [submitted, setSubmitted] = useState(false);
  return (
    <div className="loginScreenOuterContainer">
      <div className="loginFormContainer">
        {submitted ? (
          <div className="bookDemoSuccessMsg">
            Thank you! <br /> <br />
            We have sent you an email.
            <br /> <br />
            Please confirm your appointment by clicking on the link sent in the
            email.
          </div>
        ) : (
          <>
            <BookFormContents setSubmitted={setSubmitted} />
          </>
        )}
      </div>
    </div>
  );
}
export default Book;
