function PlusBullet() {
  return (
    <span
      style={{ fontWeight: "bold", fontSize: "2rem", marginRight: "2.5rem" }}
    >
      +
    </span>
  );
}
export default PlusBullet;
