import { useEffect } from "react";
import "./terms.css";

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0); // apparenttly this is needed otherwise the page may open a bit below based on where it was in the previous screen
  }, []);
  return (
    <div className="termsContainer">
      <div>
        <h4>Terms and Conditions</h4>
        <div className="termsPara">
          These Website Standard Terms and Conditions written on this webpage
          shall manage your use of our website, ScalerPlus.com accessible at
          www.ScalerPlus.com. These Terms will be applied fully and affect to
          your use of this Website. By using this Website, you agreed to accept
          all terms and conditions written in here. You must not use this
          Website if you disagree with any of these Website Standard Terms and
          Conditions.
        </div>
        <div className="termsPara">
          Minors or people below 18 years old are not allowed to use this
          Website.
        </div>
        <div className="termsPara">
          Please read our Privacy policy section regarding your personal
          information and usage of the website would be considered acceptance of
          <a href="/privacy"> Privacy Policy.</a>
        </div>
        <h5>1. General:</h5>
        <div className="termsPara">
          1.1 We reserve the right to change these terms and conditions at any
          time. Any such changes will take effect when posted on the website and
          it is your responsibility to read the terms and conditions on each
          occasion you use this website and your continued use of the website
          shall signify your acceptance to be bound by the latest terms and
          conditions.
        </div>
        <div className="termsPara">
          1.2 If you are not a consumer, you confirm that you have authority to
          bind any business on whose behalf you use this website
        </div>
        <div className="termsPara">
          1.3 When you use certain ScalerPlus.com services please read the
          additional information provided in connection with such services as
          this information will provide you with full details on how to use
          those services.
        </div>
        <div className="termsPara">
          1.4 Certain website services will require registration and subsequent
          access to those services will be subject to an approved login name and
          password ("Password Details"). Information that you provide on this
          website when registering and/or placing orders must be accurate and
          complete. Passwords Details are accepted and may be withdrawn at our
          sole discretion and are exclusive to you and non-transferable and must
          be treated as strictly confidential at all times. In the event you
          have any concerns regarding your Password Details or become aware of
          any misuse then you must inform us immediately (via contact us Page)
        </div>
        <div className="termsPara">
          1.5 You must not use our website: a) to access information which you
          have no legitimate reason for accessing; b) in a way which may
          interfere with the provision of our website to you or any other
          customer, including, without limitation, attempting to place orders in
          contravention of these terms and conditions or the law; c) If we
          reasonably believe you have misused our website, we may suspend or
          cancel your access to it and/or cancel any orders you have placed;
        </div>
        <div className="termsPara">
          1.6 Access to our website may temporarily be restricted for repairs,
          standard maintenance or the introduction of new services.
        </div>
        <div className="termsPara">
          1.7 We may change the website in any way or delete features at any
          time and for any reason.
        </div>
        <div className="termsPara">
          1.8 We reserve the right to decline a new customer registration or
          order, including multiple registrations or orders, or suspend or
          terminate a customer’s account, order, or access to all or any part of
          the website at any time, at our sole discretion and without notice.
        </div>
        <div className="termsPara">
          {" "}
          1.9 Certain video games and DVDs are the subject of a legally
          prescribed age restriction, determined by the British Board of Film
          Classification. The classifications are 12, 15, and 18 . The
          classification reflects the content of the video game or DVD
          (violence, language, sex, drug abuse etc). You are therefore required
          to confirm that you are over 18. We carry out further checks to verify
          your age with a registered credit reference agency and, if we are
          unable to verify you are over 18, we will cancel your order.
        </div>
        <h5>2. Order Process</h5>
        <div className="termsPara">
          {" "}
          2.1 All orders that you place on this website will be subject to
          acceptance in accordance with these terms and conditions.
        </div>
        <div className="termsPara">
          {" "}
          2.2 The confirmation from the seller or merchant do not set out the
          final details of your order (i.e. sale of ordered products) until you
          proceed and complete the payment. Till the payment is received, you
          have the right to cancel your order. The sellers also hold the same
          right till the payment is received. Following the order placement, we
          will send to you an order acknowledgement email detailing the products
          you have ordered. We can choose to contact sellers / merchants using
          any medium and, in some circumstances, may not contact the sellers. We
          hold the rights to choose the merchant we partner with for an order
          fulfilment and depending on the ordered products and availability of
          resources and knowledge of the stock level or ordered items or any
          other reason we may choose not to reach out to any merchants. Until
          the confirmation email is sent by ScalerPlus.com and payment is
          received from you the sale of products / goods is not complete and you
          don’t get the ownership. We reserve the right to choose how , when and
          which merchants to contact for the orders received
        </div>
        <div className="termsPara">
          2.3 Acceptance of your order and the completion of the contract
          between you and us will take place on despatch to you of the products
          ordered unless your order is placed in breach of these terms and
          conditions and/or we have notified you that we do not accept your
          order or you have cancelled it
        </div>
        <div className="termsPara">
          2.4 We may impose restrictions on the number of single product lines
          that may be ordered or fulfilled. Orders are subject to availability
          and at the discretion of the sellers. Quantities of goods supplied per
          order and/or per customer, including those goods on price promotion,
          may be limited if the order limits availability for other customers,
          takes in our reasonable opinion, unfair use of our offers, or creates
          a potential health and safety issue during delivery.
        </div>
        <div className="termsPara">
          2.5 We do not file details of your order for you to subsequently
          access direct on this website, and therefore, please print out these
          terms and conditions and the order acknowledgement for your own
          records. If you wish to obtain specific details of your previous
          orders, please ContactUs.
        </div>
        <h5>3. Returns, Cancellations and Substitutions</h5>
        <div className="termsPara">
          3.1 Returns are subject to the policy mentioned on the product page as
          specified by the suplier.Please refer to Returns and refunds section
          on the product's description page.{" "}
        </div>
        <div className="termsPara">
          3.2 Sometimes the product specifications from the manufacturer or
          seller may change, in which case we will do our best to offer you a
          similar alternative. We may experience problems with the supply of
          certain products and may therefore offer a refund or credit for the
          store.{" "}
        </div>
        <div className="termsPara">
          3.3 Where applicable, you may cancel your order in accordance with
          your rights under the Consumer Contracts (Information, Cancellation &
          Additional Charges Regulations (see the Returns and refunds page for
          further details.{" "}
        </div>
        <div className="termsPara">
          3.4 All sizes and measurements are approximate but we do try to make
          sure that they are as accurate as possible.{" "}
        </div>
        <h5>4. Liability and Indemnity</h5>
        <div className="termsPara">
          4.1 Nothing in these terms and conditions excludes or limits liability
          for death or personal injury caused by negligence, fraudulent
          misrepresentation, or any other liability which may not otherwise be
          limited or excluded under applicable law.{" "}
        </div>
        <div className="termsPara">
          4.2 Subject to Section 4.1 above, ScalerPlus.com will use reasonable
          endeavours to verify the accuracy of any information on the site but
          makes no representation or warranty of any kind express or implied
          statutory or otherwise regarding the contents or availability of the
          site or that it will be timely or error-free, that defects will be
          corrected, or that the site or the server that makes it available are
          free of viruses or bugs or represents the full functionality,
          accuracy, reliability of the website ScalerPlus.com will not be
          responsible or liable to you for any loss of content or material
          uploaded or transmitted through the website and ScalerPlus.com accepts
          no liability of any kind for any loss or damage from action taken or
          taken in reliance on material or information contained on the site.
        </div>
        <div className="termsPara">
          4.3 Subject to Section 4.1 above, other than as expressly provided in
          these terms and conditions with respect to specific products and
          except for the exclusive remedies set out at Section 5 above, any
          indemnities, warranties, terms and conditions (whether express or
          implied) are hereby excluded to the fullest extent permitted under
          applicable law.{" "}
        </div>
        <div className="termsPara">
          4.4 Subject to Section 4.1 above, ScalerPlus.com will not be liable,
          in contract, tort (including, without limitation, negligence),
          pre-contract or other representations (other than fraudulent on
          negligent misrepresentations) or otherwise out of or in connection
          with the terms and conditions for any: • economic losses (including
          without limitation loss of revenues, data, profits, contracts,
          business or anticipated savings); or • loss of goodwill or reputation;
          or • loss of privacy and loss of data; or • special or indirect losses
          • suffered or incurred by that party arising out of or in connection
          with the provisions of any matter under these terms and conditions.
        </div>
        <div className="termsPara">
          4.5 Notwithstanding the above, subject to Section 4.1 ScalerPlus.com'
          aggregate liability (whether in contract, tort or otherwise) for loss
          or damage shall in any event be limited to a sum equal to the amount
          paid or payable by you for the product(s) in respect of one incident
          or series of incidents attributable to the same clause.
        </div>
        <div className="termsPara">
          {" "}
          4.6 This clause 7 does not affect your statutory rights as a consumer,
          nor does it affect your contract cancellation rights.
        </div>
        <div className="termsPara">
          {" "}
          4.7 We will take all reasonable precautions to keep the details of
          your order and payment secure, but, unless we are negligent, we cannot
          be held liable for any losses caused as a result of unauthorised
          access to information provided by you.
        </div>
        <h5>5. Miscellaneous Provisions</h5>
        <div className="termsPara">
          5.1 The contract between us shall be governed by the laws of England
          and any dispute between us will be resolved exclusively in the courts
          of England. English is the only language offered for the conclusion of
          the contract.
        </div>
        <div className="termsPara">
          {" "}
          5.2 We have selected our products on the basis that they will be used
          for domestic use only, if you are planning to use them for business
          purposes, please make sure that you are covered by the appropriate
          insurance. Where you decide to use the products in the course of a
          business, we exclude (to the fullest extent permitted by law) those
          warranties and conditions relating to fitness for a particular
          purpose. Our maximum liability to business users arising out of or in
          connection with the products shall be limited to the replacement value
          of the product in question (except in the case of death or personal
          injury caused by our negligence or in respect of fraud). In relation
          to business users, we do not accept liability for the fitness of goods
          for business purposes, nor do we accept liability for loss of use of
          the item nor any loss over and above the cost of the item in the event
          of a claim for breach of warranty or condition.
        </div>
        <div className="termsPara">
          {" "}
          5.3 ScalerPlus shall be under no liability for any delay or failure to
          deliver products or otherwise perform any obligation as specified in
          these terms and conditions if the same is wholly or partly caused
          whether directly or indirectly by circumstances beyond its reasonable
          control.{" "}
        </div>
        <div className="termsPara">
          5.4 To provide increased value to our customers, we may provide links
          to other websites or resources for you to access at your sole
          discretion. You acknowledge and agree that, as you have chosen to
          enter the linked website we are not responsible for the availability
          of such external sites or resources, and do not review or endorse and
          are not responsible or liable, directly or indirectly, for (i) the
          privacy practices of such websites, (ii) the content of such websites,
          including (without limitation) any advertising, content, products,
          goods or other materials or services on or available from such
          websites or resources or (iii) the use to which others make of these
          websites or resources, nor for any damage, loss or offence caused or
          alleged to be caused by, or in connection with, the use of or reliance
          on any such advertising, content, products, goods or other materials
          or services available on such external websites or resources.{" "}
        </div>
        <div className="termsPara">
          5.5 You may not assign or sub-contract any of your rights or
          obligations under these terms and conditions or any related order for
          products to any third party unless agreed upon in writing by
          ScalerPlus.{" "}
        </div>
        <div className="termsPara">
          5.6 ScalerPlus reserves the right to transfer, assign, novate or
          sub-contract the benefit of the whole or part of any of its rights or
          obligations under these terms and conditions or any related contract
          to any third party.
        </div>
        <div className="termsPara">
          {" "}
          5.7 If any portion of these terms and conditions is held by any
          competent authority to be invalid or unenforceable in whole or in
          part, the validity or enforceability of the other sections of these
          terms and conditions shall not be affected.{" "}
        </div>
        <div className="termsPara">
          {" "}
          5.8 These terms and conditions do not create or confer any rights or
          benefits enforceable by any person that is not a party (within the
          meaning of the U.K. Contracts (Rights of Third Parties) Act 1999){" "}
        </div>
        <div className="termsPara">
          {" "}
          5.9 No delay or failure by ScalerPlus to exercise any powers, rights
          or remedies under these terms and conditions will operate as a waiver
          of them nor will any single or partial exercise of any such powers,
          rights or remedies preclude any other or further exercise of them. Any
          waiver to be effective must be in writing and signed by an authorised
          representative of ScalerPlus.{" "}
        </div>
        <div className="termsPara">
          5.10 These terms and conditions including the documents or other
          sources referred to in these terms and conditions supersede all prior
          representations understandings and agreements between you and
          ScalerPlus relating to the use of this website (including the order of
          products) and sets forth the entire agreement and understanding
          between you and ScalerPlus for your use of this website.
        </div>
      </div>
    </div>
  );
}
export default Terms;
