import { useLocation } from "react-router-dom";
import CarouselContainer from "../../components/carousel/CarouselContainer";
import HeroBanner from "../../components/heroBanner/HeroBanner";
import EasySellingSection from "../../components/sections/esySellingSection/EasySellingSection";
import FeaturesSection from "../../components/sections/featuresSection/FeaturesSection";
import MoreBenefitsSections from "../../components/sections/moreBenefitsSection/MoreBenefitsSection";
import RegisterSection from "../../components/sections/registerSection/RegisterSection";
import { useEffect, useState } from "react";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function Home() {
  const [bottomHitAlready, setBottomHitAlready] = useState(false); // this is to track if btm of page hit once or not
  const [scrolledALittle, setscrolledALittle] = useState(false); // this is to track if they scrolled atleast a little bit

  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    /*
 2 things happen in this function:
 a- Track Partial Scroll - Registering of appropriate functions so that analytics events are not registered on every scroll and is done only once. Hence two functions. 
 b- Track Scroll to Btm - Registering of appropriate functions so that analytics events are not registered on every scroll to BTM and is done only once. Hence two functions. 
 */
    const onScrollFirstTime = function () {
      //console.log("The scrollY value is", window.scrollY);
      if (
        window.scrollY > 0 &&
        window.innerHeight + window.scrollY >= document.body.offsetHeight
      ) {
        //console.log("you're at the bottom of the page");

        if (!bottomHitAlready) {
          //console.log("Sending the bottom page event");
          analyParamsCB({
            type: "scrollBtm",
            pl: {
              pn: location.pathname,
            },
          });
          setBottomHitAlready(true);
        }
      }
    };

    const onScrollSubs = function () {
      /* do nothing */
    };

    const onScrollSomeFirstTime = function () {
      //console.log("The scrollY value is", window.scrollY);

      if (window.scrollY > 600) {
        if (!bottomHitAlready) {
          //console.log("Sending the bottom page event");
          analyParamsCB({
            type: "scrollSome",
            pl: {
              pn: location.pathname,
            },
          });
          setscrolledALittle(true);
        }
      }
    };
    const onScrollSomeSubs = function () {
      /* do nothing */
    };

    if (!scrolledALittle) {
      // this is done because the event listeners hard code the variable value so true remains true even though the state might have changed
      window.addEventListener("scroll", onScrollSomeFirstTime);
    } else {
      window.addEventListener("scroll", onScrollSomeSubs);
    }

    if (!bottomHitAlready) {
      // this is done because the event listeners hard code the variable value so true remains true even though the state might have changed
      window.addEventListener("scroll", onScrollFirstTime);
    } else {
      window.addEventListener("scroll", onScrollSubs);
    }

    return () => {
      window.removeEventListener("scroll", onScrollFirstTime);
      window.removeEventListener("scroll", onScrollSubs);
      window.removeEventListener("scroll", onScrollSomeFirstTime);
      window.removeEventListener("scroll", onScrollSomeSubs);
    };
  }, [bottomHitAlready, scrolledALittle]);

  return (
    <>
      <HeroBanner />
      <CarouselContainer />
      <EasySellingSection />
      <MoreBenefitsSections />
      <div id="features">
        <FeaturesSection />
      </div>
      <div id="register">
        <RegisterSection />
      </div>
    </>
  );
}
export default Home;
