function HorizontalLine({ height, color, margin }) {
  return (
    <div
      style={{
        height: height ? height : "2px",
        backgroundColor: color ? color : "grey",
        margin: margin ? margin : "",
        width: "100%",
      }}
    ></div>
  );
}
export default HorizontalLine;
