import "./featuresBox.css";
import contactPic from "../../../assets/contactUs.png";
import vettedPic from "../../../assets/vettedProducts.png";
import customizePic from "../../../assets/fullyCustomizable.png";
function FeaturesBox({ iconName, heading, subText }) {
  return (
    <div className="featuresBoxContainer">
      {iconName === "contactPic" && (
        <img src={contactPic} atl="contact Us" className="featuresBoxIconImg" />
      )}
      {iconName === "vettedPic" && (
        <img src={vettedPic} atl="contact Us" className="featuresBoxIconImg" />
      )}
      {iconName === "customizePic" && (
        <img
          src={customizePic}
          atl="contact Us"
          className="featuresBoxIconImg"
        />
      )}
      <div className="featuresBoxTxtHeading">
        {heading}
        <div className="featuresBoxSubText">{subText}</div>
      </div>
    </div>
  );
}
export default FeaturesBox;
