import "./App.css";

import Header from "./components/header/Header";

import Footer from "./components/footer/Footer";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./screens/home/Home";
import Privacy from "./screens/privacy/Privacy";
import Terms from "./screens/terms/Terms";
import ContactUsScreen from "./screens/contact/ContactUsScreen";
import Login from "./screens/login/Login";
import Book from "./screens/book/Book";
import { useAnlyParams } from "./hooks/useAnlyParams";
import { useContext, useEffect } from "react";
import CookieBanner from "./components/cookieBanner/CookieBanner";
import { CookieAccptContext } from "./store/cookieAccptContext";

function App() {
  const location = useLocation();
  // console.log(location);
  const analyParamsCB = useAnlyParams();
  const { cookieAccpt } = useContext(CookieAccptContext);

  useEffect(() => {
    //console.log("App UseEffect Runs !!!! ");
    analyParamsCB({
      type: "view",
      pl: { pn: location.pathname, srch: location.search }, //right now srch style string is giving us the references like ?ref=red
    });
  }, [location]); /// Imp: the location dependency makes sure that useEffect is run on every page load

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<ContactUsScreen />} />
        <Route path="/login" element={<Login />} />
        <Route path="/book" element={<Book />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
      {!cookieAccpt && <CookieBanner />}
    </>
  );
}

export default App;
