import { useState } from "react";
import { useServerAPICalls } from "../../../hooks/useServerAPICalls";
import SimpleButton from "../../UI/buttons/simple/SimpleButton";
import "./registerSection.css";
import { useAnlyParams } from "../../../hooks/useAnlyParams";
import { useLocation } from "react-router-dom";

function RegisterSection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const serverApiCallback = useServerAPICalls();

  const registerationHandler = async () => {
    //console.log("Going to register ", email, name);
    if (name === "") {
      analyParamsCB({
        type: "click",
        pl: {
          pn: location.pathname,
          clk: "registerBtn",
          field: "Fail_NameInCorrect",
        },
      });
      setShowError("Please Enter Name");
      return;
    }
    if (email === "" || !email.includes("@") || !email.includes(".")) {
      analyParamsCB({
        type: "click",
        pl: {
          pn: location.pathname,
          clk: "registerBtn",
          field: "Fail_EmailInCorrect",
        },
      });
      setShowError("Email Not correct");
      return;
    }

    const data = await serverApiCallback("post", "register", { name, email });
    //console.log("Response is ", data.isError);
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: "registerBtn",
        field: "Success_" + email,
      },
    });
    setFormSuccess(true);
  };
  return (
    <>
      <div className="registerSectionHeading"> REGISTER</div>
      <div className="registerSectionContainer">
        <div className="registerSectionPrimaryText">
          Join our October Waitlist so that you don’t miss out on 1000s of
          Profitable Products
        </div>
        <div className="registerSectionFormContainer">
          {formSuccess ? (
            <div className="registerSectionFormSuccessMsgContainer">
              THANK YOU FOR REGISTERING!
              <div className="registerSectionSuccessSecondaryMsg">
                We have sent you an email confirmation. We will keep you
                informed !
              </div>
            </div>
          ) : (
            <>
              <div className="registerSectionFormHeading">
                Join October's WaitList
              </div>
              <div className="registerSectionFormInnerContainer">
                <div className="registerSectionFormInputRows">
                  <div>Name</div>
                  <input
                    placeholder=" Enter your name"
                    className="regsiterSectionFormInput"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="registerSectionFormInputRows">
                  <div>Email</div>
                  <input
                    placeholder=" Enter your email"
                    className="regsiterSectionFormInput"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              {showError && (
                <div className="registerSectionErrorRow">{showError}</div>
              )}
              <div style={{ fontSize: "0.8rem" }}>No Payment Needed</div>
              <div className="registerSectionSignupBtnRow">
                <SimpleButton
                  title={"Register"}
                  color={"white"}
                  backgroundColor={"#7030A0"}
                  padding={".6rem 1rem"}
                  fontSize={"1.2rem"}
                  clickHandler={() => registerationHandler()}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default RegisterSection;
