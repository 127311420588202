import { useLocation, useNavigate } from "react-router-dom";
import SimpleButton from "../UI/buttons/simple/SimpleButton";
import "./heroBanner.css";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function HeroBanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const linkClickHandler = (linkName) => {
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: "heroLink" + linkName,
      },
    });
    navigate("/" + linkName);
  };

  return (
    <div className="heroBannerContainer">
      <div className="heroBannerMainText">
        <span className="heroBannerlightBlueColorText">EASIEST </span>
        AND
        <span className="heroBannerGreenColorText"> FASTEST </span>
        WAY TO E-COMMERCE & FBA SUCCESS
      </div>
      <div className="heroBannerButtonRow">
        <SimpleButton
          title="SIGN UP"
          color={"white"}
          backgroundColor={"#7030A0"}
          clickHandler={() => linkClickHandler("#register")}
        />
        <SimpleButton
          title="Book A Demo"
          color={"white"}
          backgroundColor={"#C55A11"}
          clickHandler={() => linkClickHandler("book")}
        />
      </div>
      <div className="heroBannerBottomText">
        All in One: Sourcing, Shipping, Storage and Selling
      </div>
    </div>
  );
}
export default HeroBanner;
