import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./carouselContainer.css";
import { useEffect, useState } from "react";
import CarouselItemsContainer from "./carouselItemsContainer/CarouselItemsContainer";
import growPic from "./growPic.svg";
import rocketPic from "../../assets/rocketPic.svg";
import clicksPic from "../../assets/clicksPic.png";

function CarouselContainer() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <div className="carouselContainerOuter">
      <Carousel
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        centerMode={true}
        showStatus={false}
        centerSlidePercentage={width > 900 ? 80 : 100}
        swipeable={width > 720 ? true : false}
      >
        <CarouselItemsContainer>
          <div style={{ width: "210px" }}>
            <img src={growPic} alt="" />
            <div style={{ fontWeight: "bold", marginBottom: "25px" }}>
              {" "}
              GROW WITH AS LITTLE AS £100
            </div>
          </div>
          <div
            style={{
              width: "350px",
              color: "#00b0f0",
            }}
          >
            <ul className="carouselItemsUL">
              <li
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  marginBottom: "25px",
                }}
                className="carouselItemListBullet"
              >
                Let your hard earned money work for you
              </li>
              <li
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  marginBottom: "25px",
                }}
                className="carouselItemListBullet"
              >
                Starting from £100 get your profitable business
              </li>
            </ul>
          </div>
        </CarouselItemsContainer>
        <CarouselItemsContainer>
          <div style={{ width: "210px" }}>
            <img src={rocketPic} alt="" />
            <div style={{ fontWeight: "bold", marginBottom: "25px" }}>
              {" "}
              GO LIVE IN JUST 30 SECONDS
            </div>
          </div>
          <div
            style={{
              width: "350px",
              color: "#00b0f0",
            }}
          >
            <ul className="carouselItemsUL">
              <li
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  marginBottom: "25px",
                  textAlign: "left",
                }}
                className="carouselItemListBullet"
              >
                Automatically go live across your website & platforms (Amz,
                eBay, Walmart)
              </li>
              <li
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  marginBottom: "25px",
                  textAlign: "left",
                }}
                className="carouselItemListBullet"
              >
                No Manual work & Auto Documentation at every Stage
              </li>
              {/* <li
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  marginBottom: "25px",
                  textAlign: "left",
                }}
                className="carouselItemListBullet"
              >
                A
              </li> */}
            </ul>
          </div>
        </CarouselItemsContainer>
        <CarouselItemsContainer>
          <div style={{ width: "210px" }}>
            <img src={clicksPic} alt="" style={{ maxHeight: "210px" }} />
            <div style={{ fontWeight: "bold", marginBottom: "25px" }}>
              {" "}
              Just 3 CLICKS to get PROFITABLE products
            </div>
          </div>
          <div
            style={{
              width: "350px",
              color: "#00b0f0",
            }}
          >
            <ul className="carouselItemsUL">
              <li
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  marginBottom: "25px",
                  textAlign: "left",
                }}
                className="carouselItemListBullet"
              >
                Set Budget
              </li>
              <li
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  marginBottom: "25px",
                  textAlign: "left",
                }}
                className="carouselItemListBullet"
              >
                Set ROI
              </li>
              <li
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  marginBottom: "25px",
                  textAlign: "left",
                }}
                className="carouselItemListBullet"
              >
                Choose Product to Sell
              </li>
            </ul>
          </div>
        </CarouselItemsContainer>
      </Carousel>
    </div>
  );
}
export default CarouselContainer;
