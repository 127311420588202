import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addDays, getDay, setHours, setMinutes, setSeconds } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import SimpleButton from "../../components/UI/buttons/simple/SimpleButton";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function BookFormContents({ setSubmitted }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [startDate, setStartDate] = useState();

  const analyParamsCB = useAnlyParams();
  const location = useLocation();
  const serverApiCallback = useServerAPICalls();

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const bookingHandler = async () => {
    //console.log("Booking called with Time ", startDate);
    if (name === "") {
      analyParamsCB({
        type: "click",
        pl: {
          pn: location.pathname,
          clk: "registerBtn",
          field: "Fail_NameInCorrect",
        },
      });
      setShowError("Please Enter Name");
      return;
    }
    if (email === "" || !email.includes("@") || !email.includes(".")) {
      analyParamsCB({
        type: "click",
        pl: {
          pn: location.pathname,
          clk: "registerBtn",
          field: "Fail_EmailInCorrect",
        },
      });
      setShowError("Email Not correct");
      return;
    }
    if (!startDate) {
      analyParamsCB({
        type: "click",
        pl: {
          pn: location.pathname,
          clk: "registerBtn",
          field: "Fail_ApptDateMissing",
        },
      });
      setShowError("Please Select Date and Time");
      return;
    }
    const data = await serverApiCallback("post", "register", {
      name,
      email,
      type: "Demo",
      apptTime: startDate,
    });
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: "registerBtn",
        field: "Success_" + email,
      },
    });
    //console.log("Response is ", data.isError);
    setSubmitted(true);
  };
  return (
    <>
      <div className="loginFormHeading"> BOOK AN APPOINTMENT</div>
      <div className="loginFormInputLabel">Name</div>
      <div>
        <input
          placeholder="Your name"
          type="email"
          className="loginFormInput"
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="loginFormInputLabel">Email</div>
      <div>
        <input
          placeholder="Your email"
          type="email"
          className="loginFormInput"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="bookDemoPickTimeRow">Pick a time </div>
      <div className="bookFormDatePickerContainer">
        <ReactDatePicker
          showIcon
          selected={addDays(new Date(), 8)}
          onChange={(date) => setStartDate(date)}
          filterDate={isWeekday}
          showTimeSelect
          includeTimes={[
            setHours(setMinutes(new Date(), 0), 13),
            setHours(setMinutes(new Date(), 30), 13),
            setHours(setMinutes(new Date(), 0), 14),
            setHours(setMinutes(new Date(), 30), 14),
            setHours(setMinutes(new Date(), 30), 15),
          ]}
        />
      </div>
      {showError && <div className="bookDemoErrorRow">{showError}</div>}
      <div className="loginFormLoginBtnContainer">
        <SimpleButton
          title={"BOOK"}
          backgroundColor={"#00b0f0"}
          padding={"0.8rem "}
          clickHandler={() => bookingHandler()}
        />
      </div>
    </>
  );
}
export default BookFormContents;
