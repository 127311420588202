function SimpleButton({
  color,
  backgroundColor,
  padding,
  fontSize,
  title,
  clickHandler,
}) {
  return (
    <span
      style={{
        color: color,
        backgroundColor: backgroundColor,
        padding: padding ? padding : "20px",
        borderRadius: "10px",
        margin: "0 15px",
        cursor: "pointer",
      }}
      onClick={clickHandler ? () => clickHandler() : () => {}}
    >
      {title}
    </span>
  );
}
export default SimpleButton;
