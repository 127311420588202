import { useLocation, useNavigate } from "react-router-dom";
import SimpleButton from "../../components/UI/buttons/simple/SimpleButton";
import "./login.css";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { useState } from "react";
function Login() {
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState();
  const navigate = useNavigate();
  const analyParamsCB = useAnlyParams();
  const location = useLocation();

  const loginHandler = () => {
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: "loginBtn",
        field: "Email_" + email,
      },
    });
    setShowError("Invalid Credentials. Please Try Again !");
  };
  return (
    <div className="loginScreenOuterContainer">
      <div className="loginFormContainer">
        <div className="loginFormHeading"> LOGIN TO ACCOUNT</div>
        <div className="loginFormInputLabel">Email</div>
        <div>
          <input
            placeholder="Enter email"
            type="email"
            className="loginFormInput"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="loginFormInputLabel">Password</div>
        <div>
          <input
            placeholder="Enter Password"
            type="password"
            className="loginFormInput"
          />
        </div>
        {showError && <div className="loginErrorRow">{showError}</div>}
        <div className="loginFormLoginBtnContainer">
          <SimpleButton
            title={"LOGIN"}
            backgroundColor={"#00b0f0"}
            padding={"0.8rem "}
            clickHandler={() => loginHandler()}
          />
        </div>
        <div className="loginFormRegisterLine">
          Dont have an account ?{" "}
          <span
            style={{ color: "#00b0f0", cursor: "pointer" }}
            onClick={() => {
              analyParamsCB({
                type: "click",
                pl: {
                  pn: location.pathname,
                  clk: "registerInsteadLink",
                },
              });
              navigate("/#register");
            }}
          >
            {" "}
            Register
          </span>
        </div>
      </div>
    </div>
  );
}
export default Login;
