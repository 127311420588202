import "./moreBenefitsSection.css";
import laptopPic from "../../../assets/laptopPic.png";
import HorizontalLine from "../../UI/horizontalLine/HorizontalLine";

function MoreBenefitsSections() {
  return (
    <>
      <HorizontalLine height={"5px"} color={"#D3D3D3"} margin={"1rem 0"} />
      <div className="moreBenefitsContainer">
        <div className="moreBenefitsTextContainer">
          <ul className="moreBenefitsTextUL">
            <li className="moreBenifitsLI">
              All Products with Guaranteed Suppliers
            </li>
            <li className="moreBenifitsLI">
              Accurate ROI- inclusive of storage and shipping cost
            </li>
            <li className="moreBenifitsLI">
              Auto Documentation – auto unGating and no IP Claims
            </li>
          </ul>
        </div>
        <div style={{ maxWidth: "550px" }}>
          <img
            src={laptopPic}
            className="moreBenefitsLaptopPic"
            atl="Preview in Laptop "
          />
        </div>
      </div>
    </>
  );
}
export default MoreBenefitsSections;
