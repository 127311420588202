import { useState } from "react";
import "./header.css";
import { IoMenu } from "react-icons/io5";
import SimpleButton from "../UI/buttons/simple/SimpleButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function Header() {
  const [showHiddenMenu, setShowHiddenMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const linkClickHandler = (linkName, hidden) => {
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: hidden
          ? "headerLink" + "Hidden_" + linkName
          : "headerLink_" + linkName,
      },
    });
    linkName === "brandName" ? navigate("/") : navigate("/" + linkName);
  };
  return (
    <>
      <div className="headerContainer">
        <div
          className="headerBrandContainer"
          onClick={() => {
            linkClickHandler("brandName");
          }}
        >
          <span className="headerBrandfirstWord">Scaler</span>
          <span className="headerBrandsecondWord">Plus</span>
        </div>
        <div className="headerlinksContainer">
          <div
            className="headerlinkItem"
            onClick={() => {
              linkClickHandler("contact");
            }}
          >
            ContactUs
          </div>
          <div
            className="headerlinkItem"
            onClick={() => {
              linkClickHandler("#features");
            }}
          >
            Features
          </div>
          <div
            className="headerlinkItem"
            onClick={() => {
              linkClickHandler("#register");
            }}
          >
            Register
          </div>
          <div
            className="headerlinkItem"
            onClick={() => {
              linkClickHandler("login");
            }}
          >
            Login
          </div>
          <div className="headerHiddenRegisterBtn">
            <SimpleButton
              title={"Register"}
              backgroundColor={"#002060"}
              padding={"12px 20px"}
              clickHandler={() => {
                linkClickHandler("#register", true);
              }}
            />
          </div>
          <div
            className="headerHiddenMenuIconContainer"
            onClick={() => {
              setShowHiddenMenu(!showHiddenMenu);
            }}
          >
            <IoMenu size={"30"} color="#002060" />
          </div>
        </div>
      </div>
      <div
        className={"headerBtmHiddenLinksContainer"}
        style={showHiddenMenu ? { height: "120px" } : { height: "0px" }}
      >
        <div
          className="headerHiddenlinkItem"
          onClick={() => {
            linkClickHandler("contact", true);
          }}
        >
          ContactUs
        </div>
        <div
          className="headerHiddenlinkItem"
          onClick={() => {
            linkClickHandler("#features", true);
          }}
        >
          Features
        </div>
        <div
          className="headerHiddenlinkItem"
          onClick={() => {
            linkClickHandler("#features", true);
          }}
        >
          Register
        </div>
        <div
          className="headerHiddenlinkItem"
          onClick={() => {
            linkClickHandler("login", true);
          }}
        >
          Login
        </div>
      </div>
    </>
  );
}
export default Header;
