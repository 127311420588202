import PlusBullet from "../../UI/bullets/PlusBullet";
import "./featuresListSection.css";
function FeaturesListSection() {
  return (
    <div>
      <div style={{ maxWidth: "700px", margin: "0 auto" }}>
        <div className="featuresListSectionText">
          <PlusBullet />
          Fully Automated
        </div>
        <div className="featuresListSectionText">
          <PlusBullet />
          Get Shipping and Storage Options
        </div>
        <div className="featuresListSectionText">
          <PlusBullet />
          Sell across Platforms with synced Inventory
        </div>
        <div className="featuresListSectionText">
          <PlusBullet />
          Auto Reordering
        </div>
      </div>
    </div>
  );
}
export default FeaturesListSection;
